/* global generic, google, Mustache */

var site = site || {};
var Model = Model || {};
var generic = generic || {};
var google = google || {};
var pageData = page_data || {};
var sppTranslations = site.templates && site.templates.bopis_v1 && site.templates.bopis_v1.data || {};
var checkoutTranslations = (((pageData.data || {}).rb || {}).language || {}).bopis || {};
var checkoutErrorTranslations = ((pageData.data || {}).rb || {}).error_messages || {};
var locationTranslations = {
  invalidZip: sppTranslations.spp_zipcode_invalid || checkoutErrorTranslations.invalid_zip_code || 'Invalid Zipcode',
  unavailableZip: sppTranslations.spp_zipcode_unavailable || checkoutTranslations.unavailable_zip || 'Select location'
};
var location_v1 = (function ($) {
  var IS_INITIALIZED = false;
  var DEFAULTS = {
    ZIP_VALID_SYMBOLS: Drupal.settings.globals_variables.location_check_zipcode_valid_symbols_regex || '[0-9]',
    ZIP_REGEX: Drupal.settings.globals_variables.location_check_zipcode_regex || '(^\\d{5}(-\\d{4})?$)|(^[ABCEGHJKLMNPRSTVXYabceghjklmnpstvxy]{1}\\d{1}[A-Za-z]{1} ?\\d{1}[A-Za-z]{1}\\d{1})$',
    ZIP_FORMAT_SOLID: typeof Drupal.settings.globals_variables.location_zip_format_solid !== 'undefined' ? Drupal.settings.globals_variables.location_zip_format_solid : true,
    EVENTS: {
      LocationZipCodeNew: 'Location:zipCode:new',
      LocationZipCodeFail: 'Location:zipCode:fail',
      LocationZipCodeFallback: 'Location:zipCode:fallback',
      LocationZipCodeFallbackFail: 'Location:zipCode:fallbackFail'
    }
  };
  var locationApi = null;
  var locationCache = null;
  var isZipFormVisible = false;
  var _isLocationInitialized = false;
  var $zipInput = $('.js-location-zip-input');
  var ZIPCODE_MAXLENGTH = Drupal.settings.globals_variables.bopis_store_zipcode_length;
  var $container = $('.location__zip-container');
  var handleShowZipForm = function () {
    isZipFormVisible = true;
    var $locationZipForm = $('.js-location-zip-form', $container);
    var $locationSearchCancel = $('.js-location-search-cancel', $container);
    var $locationZipEdit = $('.js-location__zip-edit', $container);

    $($container).addClass('visible-zip-form');
    $locationZipForm.removeClass('hidden');
    $locationSearchCancel.removeClass('hidden');
    $locationZipEdit.addClass('hidden');
  };
  var handleHideZipForm = function () {
    isZipFormVisible = false;
    var $locationZipForm = $('.js-location-zip-form', $container);
    var $locationSearchCancel = $('.js-location-search-cancel', $container);
    var $locationZipEdit = $('.js-location__zip-edit', $container);

    $($container).removeClass('visible-zip-form');
    $locationZipForm.addClass('hidden');
    $zipInput.val('');
    $locationZipEdit.removeClass('hidden');
    $locationSearchCancel.addClass('hidden');
  };
  var handleZipCode = function (zip, sameLocation) {
    var $showLocationZipForm = $('.js-show-location-zip-form');

    locationCache.setUserZipCode(zip);
    $showLocationZipForm.html(zip);
    $container.removeClass('invalid-zip');
    if (event) {
      $(event.target).parent().removeClass('invalid-zip');
    }
    !sameLocation && $(document).trigger(DEFAULTS.EVENTS.LocationZipCodeNew, {
      zip: zip
    });
  };
  var handleZipCodeError = function () {
    var $elem = $(event && event.target || this);
    var $showLocationZipForm = $('.js-show-location-zip-form', $container);

    $showLocationZipForm.html(locationTranslations.invalidZip);
    $('.bopis-invalid-zip').html(locationTranslations.invalidZip);
    $container.addClass('invalid-zip');
    $container.find('.loading-effect-active').loadingEffect('destroy');
    $elem.parent().parent().addClass('invalid-zip');
  };
  var handleZipCodeFailed = function () {
    var $showLocationZipForm = $('.js-show-location-zip-form', $container);

    $showLocationZipForm.html(locationTranslations.unavailableZip);
    $('.bopis-invalid-zip').html(locationTranslations.unavailableZip);
    $container.addClass('invalid-zip');
    $container.find('.loading-effect-active').loadingEffect('destroy');
    if (event) {
      $(event.target).parents('.js-bopis-zip-form-modal').addClass('invalid-zip');
    }
  };

  $(document).on(DEFAULTS.EVENTS.LocationZipCodeFallback, function (e, params) {
    var userCoords = params.userCoords;
    var skipCache = params.skipCache;

    locationApi.getUserZipCode({
      latitude: userCoords.latitude,
      longitude: userCoords.longitude
    }, skipCache)
      .then(function (zip) {
        handleZipCode(zip, true);
      })
      .catch(handleZipCodeFailed);
  });

  var that = {
    EVENTS: DEFAULTS.EVENTS,
    getIsLocationInitialized: function () {
      return _isLocationInitialized;
    },
    /* Zip code field should allow only values specific to user locale
     * and "Enter", "Backspace", "ArrowLeft" and "ArrowRight" keys. */
    isInvalidZipCharacter: function (e) {
      var regex = new RegExp(DEFAULTS.ZIP_VALID_SYMBOLS);
      var key = e.key;
      var keyCode = Number(e.which);

      return !regex.test(key) && keyCode !== 13 && keyCode !== 8 && keyCode !== 37 && keyCode !== 39;
    },
    isValidZip: function (zip) {
      if (typeof DEFAULTS.ZIP_REGEX !== 'undefined') {
        var zipRegEx = new RegExp(DEFAULTS.ZIP_REGEX, 'i');

        return zipRegEx.test(zip);
      }

      return true;
    },
    bindUserZipForm: function ($formContainer) {
      // Reselect the container in cases when panel is rerendered
      $container = $('.location__zip-container');
      var $context = $formContainer || $(document);
      var $zipInput = $context.find('.js-location-zip-input');
      var $loadingEl = $context.find('.js-location-search-submit');
      var loadingProps = {
        text: 'Loading',
        fontSize: '14px',
        alignContent: 'center',
        type: 'spinner'
      };
      var _selectZip = function () {
        var $currentZipInput = $(this).parent().find('.js-location-zip-input');
        var zipValue = $currentZipInput.val();

        // Adjust zip for countries with spaced zipcode format
        if (!DEFAULTS.ZIP_FORMAT_SOLID) {
          if (zipValue.length < ZIPCODE_MAXLENGTH && !(/\s/).test(zipValue)) {
            zipValue = [zipValue.slice(0, (zipValue.length - zipValue.length % 2) / 2), zipValue.slice((zipValue.length - zipValue.length % 2) / 2)].join(' ');
          }
        }
        if (that.isValidZip(zipValue)) {
          $loadingEl.loadingEffect(loadingProps);
          handleZipCode(zipValue);
          $currentZipInput.val('');
        } else {
          handleZipCodeError.bind(this)();
        }
      };

      $('.js-location__zip-edit', $context).off('click').on('click', handleShowZipForm);
      $('.js-location-search-cancel', $context).off('click').on('click', handleHideZipForm);
      $('.js-location-search-submit', $context).off('click').on('click', _selectZip);
      $('.js-location-search-icon', $context).off('click').on('click', _selectZip);

      $('.js-location-current-location', $context).off('click').on('click', function () {
        $loadingEl.loadingEffect(loadingProps);
        that.getUserZipCode(true)
          .then(function () {
            $zipInput.val('');
            $container.find('.loading-effect-active').loadingEffect('destroy');
          })
          .catch(function () {
            $zipInput.val('');
            handleZipCodeFailed();
          });
      });

      $zipInput.off('keydown').on('keydown', function (e) {
        var prevVal = e.target.value;
        var val = e.key.toUpperCase();

        // Allow copy and paste zipcodes
        if (e.which === 8 || ((e.which === 65 || e.which === 86 || e.which === 67) && (e.ctrlKey === true || e.metaKey === true)) || (e.which >= 35 && e.which <= 40)) {
          return;
        }
        if (e.which === 13) {
          _selectZip.bind(this)();

          return false;
        }
        if (that.isInvalidZipCharacter(e) || e.target.value.length >= ZIPCODE_MAXLENGTH) {
          e.preventDefault();

          return false;
        }
        if (!val.match(/[a-zA-Z\s]|\d/)) {
          e.preventDefault();
        }
        // TODO: Add input mask for alphanumeric zipcodes
      });
    },

    getUserZipCode: function (forceBrowserLocation) {
      var cachedZipCode = locationCache.getUserZipCode();
      var useBrowserLoc = Boolean(Drupal.settings.globals_variables.bopis_use_browser_location);

      if (cachedZipCode && !forceBrowserLocation) {
        return new Promise(function (resolve, reject) {
          handleZipCode(cachedZipCode);
          resolve({
            zip: cachedZipCode,
            shouldSkipData: false
          });
        });
      }

      if (!useBrowserLoc && !forceBrowserLocation) {
        return new Promise(function () {
          handleZipCodeFailed();
          $(document).trigger(DEFAULTS.EVENTS.LocationZipCodeFallbackFail);
        });
      }

      return new Promise(function (resolve, reject) {
        locationApi.getUserLocation()
          .then(function (userCoords) {
            locationApi.getUserZipCode({
              latitude: userCoords.latitude,
              longitude: userCoords.longitude
            }, forceBrowserLocation)
              .then(function (zip) {
                if (!that.isValidZip(zip)) {
                  handleZipCodeFailed();
                  reject();
                } else {
                  handleZipCode(zip);

                  return zip;
                }
              })
              .then(function (zip) {
                resolve({
                  zip: zip,
                  shouldSkipData: false
                });
              })
              .catch(function () {
                handleZipCodeFailed();
              });
          })
          .catch(function () {
            reject();
            $(document).trigger(
              DEFAULTS.EVENTS.LocationZipCodeFail,
              { skipCache: forceBrowserLocation }
            );
          });
      });
    },

    setUserZipCode: function (newZipCode) {
      handleZipCode(newZipCode);
    },

    hideZipForm: handleHideZipForm,

    initLocation() {
      that.bindUserZipForm();
      _isLocationInitialized = true;
    }
  };

  return {
    init: function (locationApiModel, locationCacheModel) {
      if (!IS_INITIALIZED) {
        locationApi = locationApiModel;
        locationCache = locationCacheModel;

        IS_INITIALIZED = true;
      }
    },
    getInstance: function () {
      return that;
    }
  };
})(jQuery);

/** Location cache hadling*/
Model.LocationCache = (function () {
  var zipcodeLifetimeExtension = 
    Drupal.settings.globals_variables.bopis_cache_lifetime_extension ? 4 * 24 * 30 : 1;
  var SS_KEYS = {
    USER_ZIP_CODE: 'user_zip_code'
  };
  var SS_NAMESPACE = 'LOCATION+';
  var expirationTime = 15 * 60000;
  var that = {};

  /**
   * Getter for ss selected courier store key
   *
   * @return {string}
  */
  that.getSSUserZipCodeKey = function () {
    return SS_NAMESPACE + SS_KEYS.USER_ZIP_CODE;
  };

  /**
   * Set the user zip code into the local storage for later usage
   * @return {void} Returns nothing
  */
  that.setUserZipCode = function (zip) {
    var data = {
      zip,
      expires: Date.now() + (expirationTime * zipcodeLifetimeExtension)
    };

    localStorage.setItem(that.getSSUserZipCodeKey(), JSON.stringify(data));
  };

  /**
   * Get the user zip code from the DB
   * @return {number} zip
  */
  that.getUserZipCode = function () {
    var userZipStorageData = localStorage.getItem(that.getSSUserZipCodeKey());
    var parsedUserZipData;
    var isExpired;

    if (!userZipStorageData) {
      return null;
    }

    parsedUserZipData = JSON.parse(userZipStorageData);
    isExpired = parsedUserZipData.expires < Date.now();

    if (isExpired) {
      return null;
    }

    return parsedUserZipData.zip;
  };

  return {
    getInstance: function () {
      return that;
    }
  };
}());

/**
  * Singleton model that handles user location.
  *
*/
Model.LocationApi = (function () {
  var IS_INITIALIZED = false;
  var locationCache = null;
  var REGEX = new RegExp(Drupal.settings.globals_variables.location_zipcode_string_extraction || /\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d/g);
  var useGoogleZip = Drupal.settings.globals_variables.location_google_zip || false;
  var that = {
    /**
     * Returns current user location if possible;
     * @return {Promise<coords>} Returns Promise object which resolves with user location
    */
    getUserLocation: function () {
      return new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            resolve(position.coords);
          },
          function (error) {
            reject(error);
          },
          { timeout: 5000 }
        );
      });
    },
    /**
     * Returns users current zip code.
     * @return {Promise<number>} Returns Promise object which resolves with user zip code
    */
    getUserZipCode: function (userCoords, skipCache) {
      var cachedZipCode = locationCache.getUserZipCode();

      if (cachedZipCode && !skipCache) {
        return Promise.resolve(cachedZipCode);
      }

      if (useGoogleZip) {
        return this.getGoogleZip(userCoords);
      } else {
        return this.getAkamaiZip();
      }
    },
    /**
      * Returns users current zip code from a google API call
      * @return {Promise<number>} Returns Promise object which resolves with user zip code
    */
    getGoogleZip: function (userCoords) {
      return that.getMapApi()
        .then(function (mapApi) {
          var point = new mapApi.maps.LatLng(userCoords.latitude, userCoords.longitude);

          return new Promise(function (resolve, reject) {
            new mapApi.maps.Geocoder().geocode({ 'latLng': point }, function (res, status) {
              if (status === mapApi.maps.GeocoderStatus.OK && typeof res[0] !== 'undefined') {
                var zip = res[0].formatted_address.match(REGEX);

                if (zip) {
                  return resolve(zip[1] || zip[0]);
                } else {
                  reject('Unable to look-up postal code');
                }
              } else {
                reject('Unable to look-up geolocation');
              }
            });
          });
        });
    },

    /**
      * Returns users current zip code from parsing akamai cookie.
      * @return {Promise<number>} Returns Promise object which resolves with user zip code
    */
    getAkamaiZip: function() {
      var akamaiLocation = $.cookie('aka_edgescape')
      var akamaiZipSplit = akamaiLocation && akamaiLocation.split('zip=');
      var akamaiZipList = akamaiZipSplit && akamaiZipSplit[1];
      var akamaiZipArr = akamaiZipList && akamaiZipList.replaceAll('-', '+').replaceAll(' ', '+').split('+');
      var akamaiZip = akamaiZipArr && akamaiZipArr[0];
      if (akamaiZip) {
        akamaiZip = akamaiZip.split('-')[0];
      }
      return Promise.resolve(akamaiZip || '');
    },

    /**
     * Init Google API
     * @return {Promise<googleApi>}
    */
    getMapApi: function () {
      return new Promise(function (resolve, reject) {
        var googleAPIKey = Drupal.settings.globals_variables.google_map_api_key;
        if (google && google.maps) {
          resolve(google);
        } else {
          var gLanguage = (navigator.language || navigator.userLanguage).split('-')[0];
          var protocol = window.location.protocol === 'https:' ? 'https:' : 'http:';

          if (!googleAPIKey) {
            reject('Google API key missing');
            return false;
          }

          $.getScript(protocol + '//maps.googleapis.com/maps/api/js?key=' + googleAPIKey + '&sensor=false&language=' + gLanguage + '&v=3.8')
            .done(function () {
              resolve(google);
            })
            .fail(function (jqxhr, settings, exception) {
              reject(exception);
            });
        }
      });
    }
  };

  return {
    init: function (locationCacheService) {
      if (!IS_INITIALIZED) {
        locationCache = locationCacheService;
        IS_INITIALIZED = true;
      }
    },
    getInstance: function () {
      return that;
    }
  };
})();

var locationCache = Model.LocationCache.getInstance();

Model.LocationApi.init(locationCache);
var locationApi = Model.LocationApi.getInstance();

location_v1.init(locationApi, locationCache);
site.location_v1 = location_v1.getInstance();
